import React, { useEffect, useState, useRef } from "react";
import "./header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { service } from "../../Network/Home/Service";
import defaultPlaceHolder from "../../assets/images/blank display picture.png";
import { useDispatch, useSelector } from "react-redux";
import { getAccountDetails } from "../../Redux/User/AccountSettingsSlice";
import { convertTimeToLocal } from "../../Utils/utils";
import placeholdeImage from '../../assets/images/PlaceHolderShows.jpg'
function Header() {
  const dispatch=useDispatch()
  let detailsResponse = useSelector((state)=>state?.AccountDetails?.value)
  
  const [navScrollEffect, setNavscrollEffect] = useState(false);
  const [radioContent, setRadioContent] = useState()
  const [navLinksActive, setNavlinksActive] = useState("");
  const [userDetails, setuserDetails] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [radioActive, setRadioActive] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [continueWatching, setContinueWatching] = useState([]);
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  const [ProfileImage, setProfileImage] = useState();
  const [userName, setUserName] = useState('')
  const [notificationActive, setNotificationActive] = useState(false);
  const [notificationData, setNotificationData] = useState([])
  const navigate = useNavigate();
  const location=useLocation()
  const [userAction,setUserAction]=useState(false)
  // profile icon refs
  let clickRef = useRef(null);
  let headRef = useRef(null);
  let headRefSecond = useRef(null);
  // notification refs
  let notificationRef = useRef(null);
  let notificationIconRef = useRef(null);
  // radio refs
  let radioRef = useRef(null);
  let radioIconRef = useRef(null);
  // search refs
  let searchRef = useRef(null);
  let searchIconRef = useRef(null);
  useEffect(()=>{
    if(location?.pathname){
      switch(location?.pathname){
          case "/":
            setNavlinksActive("Home")
            break;
          case "/live":
          setNavlinksActive("Live")
          break;
          case "/shows":
          setNavlinksActive("Shows")
          break;
          case "/schedule":
          setNavlinksActive("Schedule")
          break;
          case "/catchup":
          setNavlinksActive("Catch Up")
          break;
          case "/on-demand":
          setNavlinksActive("On Demand")
          break;
          case "/how-to-watch":
          setNavlinksActive("How To Watch")
          break;
          // case "/live-events":
          // setNavlinksActive("Sunday Live")
          // break;
          case "/my-list":
          setNavlinksActive("My List")
          break;
          case "/prayer":
          setNavlinksActive("Prayer")
          break;
          default:
            setNavlinksActive("")
      }
    }
  },[location?.pathname])
  useEffect(() => {
    if (!radioActive) {
      setRadioContent(null)
    }
    else{
      setRadioContent(<iframe
        src="https://ins3.radiolize.com/public/kicctv/embed?theme=light"
        frameBorder="0"
        allowtransparency="true"
        style={{ width: "100%", minHeight: " 160px", border: "0" }}
        // style="width: 100%; min-height: 160px; border: 0;"
      ></iframe>)
    }
  }, [radioActive])
  
  useEffect(() => {

    if (detailsResponse) {
      setProfileImage(detailsResponse?.data?.user_image);
      
        setUserName(detailsResponse?.data?.first_name)
    }
  }, [detailsResponse])
  
  
  useEffect(() => {
    if (isLoggedIn) {
      service.getAccountDetails().then((response) => {
        setProfileImage(response?.data?.user_image);
        setUserName(response?.data?.first_name)
        dispatch(getAccountDetails({
          AccountDetails:response
        }))
      });
      service.getContinueWatching().then((response) => {
        if (response) {
          setContinueWatching(response.data[0]);
        }
      });
      service.getNotification().then((response) => {
        if (response) {
          setNotificationData(response.data)

        }
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!headRef?.current?.contains(e.target)) {
        if (!headRefSecond?.current?.contains(e.target)) {
          if (!clickRef?.current?.contains(e.target)) {
            setuserDetails(false); //outside click
          }
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [clickRef]);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!notificationIconRef?.current?.contains(e.target)) {
        if (!notificationRef?.current?.contains(e.target)) {
          setNotificationActive(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [notificationRef]);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!radioIconRef?.current?.contains(e.target)) {
        if (!radioRef?.current?.contains(e.target)) {
          setRadioActive(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [radioRef]);

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!searchIconRef?.current?.contains(e.target)) {
        if (!searchRef?.current?.contains(e.target)) {
          setSearchActive(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [searchRef]);
  const playIconHandler = () => {
    continueWatching?.video_id ? 
    navigate({
      pathname: "/player",
      search: encodeURI(`video_ID=${continueWatching?.video_id}`),
    })
    :
    navigate({
      pathname: "/shows",
      
    })
    setuserDetails(false);
  };
  const navScroll = () => {
    if (window.scrollY >= 100) {
      setNavscrollEffect(true);
    } else {
      setNavscrollEffect(false);
    }
  };

  window.addEventListener("scroll", navScroll);

  const clearUserData = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("deviceAnalytics");
    // service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("showId");
    eraseCookie("isLoggedIn");
    eraseCookie("showId");
    eraseCookie("videoId");
    eraseCookie("userName");
    eraseCookie("userId");
    eraseCookie("userEmail");
    eraseCookie("subscriptionId");
  };
  const eraseCookie = (name) => {
    document.cookie = name + "=; Max-Age=-99999999;";
  };

  const logoutHandler = () => {
    let user = localStorage.getItem("userId");
    service.logoutFunction(user).then((response) => {
      if (response.success == true) {
        clearUserData();
        window.location.href = "/";
      } else {
      }
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    if (searchKey != "") {
      service.getShowBySearch(searchKey).then((response) => {
        if (response?.success == true) {
          navigate("/search-results", { state: response?.data });
        }
      });
      setSearchKey("")

    }
  };

  const replaceImage = (e) => {
    //replacement of broken Image
    e.target.src = defaultPlaceHolder;
  };

  return (
    <div id="header">
      <div
        className={mobileMenu ? "overlay active" : "overlay"}
        onClick={() => setMobileMenu(false)}
      ></div>
      <div className="wrapper">
        <div className={navScrollEffect ? "active" : "containers"}>
          <div className="leftContainer">
            <Link to="/">
              <div className={searchActive?"small imageContainer":"imageContainer"}>
                <img
                  src={require("../../assets/images/kicctv logo.png")}
                  alt="Logo"
                />
              </div>
            </Link>
          </div>
          {/* <div className="leftContainer responsive">
            <Link to="/">
              <div className="imageContainer">
                <img
                  src={require("../../assets/images/kicctv logo.png")}
                  alt="Logo"
                />
              </div>
            </Link>
          </div> */}
          <div
            className={
              searchActive ? "animation centerContainer" : "centerContainer"
            }
          >
            <nav>
              <ul className="navBar">
                <Link to="/">
                  <li
                    className="navLinks"
                    onClick={() => {
                      setNavlinksActive("Home");
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    <span
                      className={
                        navLinksActive == "Home" ||
                        window.location.pathname == "/"
                          ? "active"
                          : ""
                      }
                    >
                      Home
                    </span>
                  </li>
                </Link>
                <Link to="/live">
                  <li
                    className="navLinks"
                    onClick={() => {
                      setNavlinksActive("Live");
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    <div className="imageContainer">
                      <img
                        src={require("../../assets/images/icon-dotred.png")}
                        alt="Dot"
                      />
                    </div>
                    <span
                      className={
                        navLinksActive == "Live" ||
                        window.location.pathname == "/live"
                          ? "active"
                          : ""
                      }
                    >
                      Live
                    </span>
                  </li>
                </Link>
                <Link to="/shows">
                  <li
                    className="navLinks"
                    onClick={() => {
                      setNavlinksActive("Shows");
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    <span
                      className={
                        navLinksActive == "Shows" ||
                        window.location.pathname == "/shows"
                          ? "active"
                          : ""
                      }
                    >
                      Shows
                    </span>
                  </li>
                </Link>
                <Link to="/schedule">
                  <li
                    className="navLinks"
                    onClick={() => {
                      setNavlinksActive("Schedule");
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    <span
                      className={
                        navLinksActive == "Schedule" ||
                        window.location.pathname == "/schedule"
                          ? "active"
                          : ""
                      }
                    >
                      Schedule
                    </span>
                  </li>
                </Link>
                <Link to="/catchup">
                  <li
                    className="navLinks"
                    onClick={() => {
                      setNavlinksActive("Catch Up");
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    <span
                      className={
                        navLinksActive == "Catch Up" ||
                        window.location.pathname == "/catchup"
                          ? "active"
                          : ""
                      }
                    >
                      Catch Up
                    </span>
                  </li>
                </Link>
                <Link to="/on-demand">
                  <li
                    className="navLinks"
                    onClick={() => {
                      setNavlinksActive("On Demand");
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    <span
                      className={
                        navLinksActive == "On Demand" ||
                        window.location.pathname == "/on-demand"
                          ? "active"
                          : ""
                      }
                    >
                      On Demand
                    </span>
                  </li>
                </Link>
               <Link to="/how-to-watch">
               <li
                    className="navLinks"
                    onClick={() => {
                      setNavlinksActive("How To Watch");
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    <span
                      className={
                        navLinksActive == "How To Watch" ||
                        window.location.pathname == "/how-to-watch"
                          ? "active"
                          : ""
                      }
                    >
                     How To Watch
                    </span>
                  </li>
               </Link>
                {/* <Link to="/live-events">
                  <li
                    className="navLinks"
                    onClick={() => {
                      setNavlinksActive("Sunday Live");
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    <span
                      className={
                        navLinksActive == "Sunday Live" ||
                        window.location.pathname == "/live-events"
                          ? "active"
                          : ""
                      }
                    >
                      Live Events
                    </span>
                  </li>
                </Link> */}
                {localStorage.getItem("isLoggedIn") && (
                  <Link to="/my-list">
                    <li
                      className="navLinks"
                      onClick={() => {
                        setNavlinksActive("My List");
                        document.documentElement.scrollTop = 0;
                      }}
                    >
                      <span
                        className={
                          navLinksActive == "My List" ||
                          window.location.pathname == "/my-list"
                            ? "active"
                            : ""
                        }
                      >
                        My List
                      </span>
                    </li>
                  </Link>
                )}

                <Link to="/prayer">
                  <li
                    className="navLinks"
                    onClick={() => {
                      setNavlinksActive("Prayer");
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    <span
                      className={
                        navLinksActive == "Prayer" ||
                        window.location.pathname == "/prayer"
                          ? "active"
                          : ""
                      }
                    >
                      Prayer
                    </span>
                  </li>
                </Link>
                {
                  !searchActive&&
                <a href="https://www.kicc.org.uk/donations/" target="_blank">
                  <li className="navLinks">
                    <span className="donate">Donate</span>
                  </li>
                </a>
                }
              </ul>
            </nav>
          </div>
          <div className="rightContainer">
            <ul className="icons">
              <li
                className={searchActive ? "no-active" : "icon"}
                onClick={() => setSearchActive(!searchActive)}
                ref={searchIconRef}
              >
                <img
                  src={require("../../assets/images/icon - search.png")}
                  alt="Search-Icon"
                />
              </li>
              <li className={searchActive ? "search" : "no-active"}>
                <form onSubmit={(e) => searchHandler(e)} method="get" ref={searchRef}>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchKey || ""}
                    onChange={(e) => setSearchKey(e.target.value)}
                    style={{ color: "white" }}
                  />
                  <button type="submit" onClick={() => setSearchActive(!searchActive)}>
                    <img
                      src={require("../../assets/images/icon - search.png")}
                      alt="Search-Icon"
                    />
                  </button>
                </form>
              </li>
              
              <li className="icon" 
              ref={radioIconRef}
              onClick={() => setRadioActive(!radioActive)}>
                <img
                  src={require("../../assets/images/icon - radio.png")}
                  alt="Radio-Icon"
                />
              </li>
              
              {(isLoggedIn)&&
              <li
                ref={notificationIconRef}
                className="icon"
                onClick={() => setNotificationActive(!notificationActive)}
              >
                <img
                  src={
                    require("../../assets/images/icon-notification.svg").default
                  }
                  alt="Radio-Icon"
                />
              </li>
              }
              <li
                className="icon user-icon"
                onClick={() => setuserDetails(!userDetails)}
                ref={headRef}
              >
                <img
                  src={
                    ProfileImage
                      ? ProfileImage
                      : require("../../assets/images/blank display picture.png")
                  }
                  onError={(e) => replaceImage(e)}
                  alt="User-Icon"
                  style={
                    ProfileImage && {
                      width: "50px",
                      height: "50px",
                      borderRadius: "100px",
                    }
                  }
                />
                <img className={userDetails?"arrow rotate":"arrow"} src={require("../../assets/images/down-arrow.svg").default} alt="" />
              </li>
              <li
                className="icon user-icon mobile"
                onClick={() => setuserDetails(!userDetails)}
                ref={headRefSecond}
              >
                <img
                  src={
                    ProfileImage
                      ? ProfileImage
                      : require("../../assets/images/icon - account.png")
                  }
                  onError={(e) => replaceImage(e)}
                  alt="User-Icon"
                  style={
                    ProfileImage && {
                      width: "50px",
                      height: "50px",
                      borderRadius: "100px",
                    }
                  }
                />
              </li>

              <li className="icon mobile" onClick={() => setMobileMenu(true)}>
                <img src={require("../../assets/images/menu.png")} alt="Menu" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        ref={clickRef}
        className={
          userDetails && isLoggedIn
            ? "userDetails loggedIn active"
            : userDetails && !isLoggedIn
            ? "userDetails active"
            : "userDetails"
        }
      >
        {!isLoggedIn ? (
          <ul>
            <Link to="/register" onClick={() => setuserDetails(false)}>
              <li>Register</li>
            </Link>
            <Link to="/login" onClick={() => setuserDetails(false)}>
              <li>Login</li>
            </Link>
          </ul>
        ) : (
          <div className="isLoggedIn">
            <div className="leftContainer">
              <div className="left">
                <div className="imageContainer">
                  <img
                    src={
                      `https://gizmeon.mdc.akamaized.net/thumbnails/show_logo/` +
                      continueWatching?.logo_thumb
                    }
                    onError={(e)=>e.target.src = placeholdeImage}

                    alt="Thumbnail"
                  />
                </div>
              </div>
              <div className="center">
                <h2>Continue Watching</h2>
                <p>{continueWatching?.video_title?.slice(0,10)}</p>
              </div>
              <div className="right">
                <div
                  className="playIconContainer"
                  style={{ cursor: "pointer" }}
                  onClick={playIconHandler}
                >
                  <img
                    src={require("../../assets/images/play-icon.png")}
                    alt="Play"
                  />
                </div>
              </div>
            </div>
            <div className="rightContainer">
              <div
                style={{ cursor: "pointer" }}
                className="AccountSettings"
                onClick={() => {
                  navigate("/account/account-settings");
                  setuserDetails(false);
                }}
              >
                <span>Account Settings</span>
              </div>

              <div className="Log" onClick={logoutHandler}>
                <span style={{ cursor: "pointer" }}>Log Out</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div ref={radioRef} className={radioActive ? "radio active" : "radio"}>
        {radioContent}
      </div>{" "}
      {/*( radio )*/}
      <div
        ref={notificationRef}
        className={notificationActive ? "notification active" : "notification"}
      >
        {(notificationData.length > 0)?
        (
        notificationData.map((item , index)=>(
          <div key={index} className="notificationItem" 
          onClick={()=>{
            // navigate('/live')
          setNotificationActive(false)}}>
          <div className="left">
            <div className="imageContainer">
              <img
                src={`https://gizmeon.mdc.akamaized.net/thumbnails/show_logo/`+item.thumbnail}
                onError={(e)=>e.target.src = placeholdeImage}
                alt=""
              />
            </div>
          </div>
          <div className="right">
            <div className="head">
              <h2 className="heading">{item?.title}</h2>
              {/* <span>{convertTimeToLocal(item?.starttime)}</span> */}
            </div>
            <span className="desc">{item.message}</span>
          </div>
        </div>
        )))
      :
      <div style={{marginLeft:'5px'}}>No notifications</div>
      }
        
      </div>
      <div className={mobileMenu ? "mobileMenu active" : "mobileMenu"}>
        <div className="top">
          <h3 className="heading">Menu</h3>
          <div className="imageContainer" onClick={() => setMobileMenu(false)}>
            <img
              src={require("../../assets/images/close-white.png")}
              alt="Close"
            />
          </div>
        </div>
        <ul className="menu">
          <Link to="/">
            <li>
              <span>Home</span>
            </li>
          </Link>
          <Link to="/live">
            <li>
              <span>Live</span>
              <img
                src={require("../../assets/images/icon-dotred.png")}
                alt="Red dot"
              />
            </li>
          </Link>
          <Link to="/shows">
            <li>
              <span>Shows</span>
            </li>
          </Link>
          <Link to="/schedule">
            <li>
              <span>Schedule</span>
            </li>
          </Link>
          <Link to="/catchup">
            <li>
              <span>Catch Up</span>
            </li>
          </Link>
          <Link to="/on-demand">
            <li>
              <span>OnDemand</span>
            </li>
          </Link>
          <Link to="/how-to-watch">
            <li>
              <span>How To Watch</span>
            </li>
          </Link>
          {/* <Link to="/live-events">
            <li>
              <span>Live Events</span>
            </li>
          </Link> */}
          {localStorage.getItem("isLoggedIn") && (
            <Link to="/my-list">
              <li
                className="navLinks"
                onClick={() => {
                  setNavlinksActive("My List");
                  document.documentElement.scrollTop = 0;
                }}
              >
                <span
                  className={
                    navLinksActive == "My List" ||
                    window.location.pathname == "/my-list"
                      ? "active"
                      : ""
                  }
                >
                  My List
                </span>
              </li>
            </Link>
          )}
          <Link to="/prayer">
            <li>
              <span>Prayer</span>
            </li>
          </Link>
          <a href="https://www.kicc.org.uk/donations/" target="_blank">
            <li>
              <span>Donate</span>
            </li>
          </a>
          <li className="user-mobile" onClick={()=>setUserAction(!userAction)}>
            <div className="imageContainer">
              <img
                src={
                  ProfileImage
                    ? ProfileImage
                    : require("../../assets/images/blank display picture.png")
                }
                onError={(e) => replaceImage(e)}
                alt="User"
                style={
                  ProfileImage && {
                    width: "25px",
                    height: "25px",
                    borderRadius: "100px",
                  }
                }
              />
            </div>
              <span>{userName}</span>
            <div className="arrowContainer">
              <img
                src={require("../../assets/images/right-arrow.png")}
                alt="Arrow"
              />
            </div>
          </li>
          {isLoggedIn ?
            userAction&&
            <>
          <Link to="/account/account-settings">
            <li>
              <span>Account Settings</span>
            </li>
          </Link>
          <Link to="">
            <li onClick={logoutHandler}>
              <span>Log Out</span>
            </li>
          </Link>
          </>
          :
          userAction&&
            <>
          <Link to="register">
            <li>
              <span>Register</span>
            </li>
          </Link>
          <Link to="/login">
            <li>
              <span>Login</span>
            </li>
          </Link>
          </>
          }
        </ul>
      </div>
    </div>
  );
}

export default Header;
