import React, { useState ,useEffect } from "react";
import { useSelector } from "react-redux";
import { imageUrlCheck } from "../../Utils/utils";
import "./showcard.css";
const Showcard = ({ item ,cardHover,imageUrl}) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const [imageURL,setImageURL] = useState();
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");


 useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);
  const showClickHandler = (id, showId) => {
  
  }
  const afterImageLoad = (e) => {
    let image;
    if (imageUrlCheck(thumbnailOrientation === 'PORTRAIT' ? item?.logo || item?.thumbnail : item?.logo_thumb || item?.thumbnail_350_200) === true) {
      if (thumbnailOrientation === "PORTRAIT") {
        image = item?.logo || item?.thumbnail
      } else {
        image = item?.logo_thumb || item?.thumbnail_350_200
      }
    } else {
      if (thumbnailOrientation === "PORTRAIT") {
        image =(item?.logo || item?.thumbnail)
      } else {
        image =(item?.logo_thumb || item?.thumbnail_350_200)
      }
    }
    
    setImageURL(image)
  }
  return (
    <div
    className={cardHover ? `showCardContainer cardHover ` : `showCardContainer`}
    title={item?.show_name || item?.video_title}  
    >

    <div className="square" onClick={() => showClickHandler(item?.vanity_url ? item?.vanity_url : item?.show_id ? item?.show_id : item?.event_id, item?.show_id)}>
      <div className="imageContainer">
       
        <img 
          src={imageUrl}
          alt="Show Thumbnail"
          key={item?.show_id}
          onError={(e) => (e.target.src = require("../../assets/images/show-episode-thumbnail.png"))}
          onLoad={(e) => afterImageLoad(e)}
        />
      </div>
      <div className="metaData">
        <h1 className="title">{item?.show_name|| item?.video_title}</h1>
        <div className="extraInfo">
          {
            item?.synopsis || item?.video_description &&
             <span className="description">{item?.synopsis || item?.video_description}</span>
          }
        </div>
      </div>
    </div>
   
  </div>
  );
};

export default Showcard;
