import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { service } from "../../Network/Home/Service";
import "./MyList.css";
import { convertTimeToLocal } from "../../Utils/utils";
import Showcard from "../../components/Showcard/Showcard";
function MyList() {
  const navigate = useNavigate();
  const [showList, setShowList] = useState([]);
  const [ReminderShows, setReminderShows] = useState([]);
  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let userId = service.getCookie("userId");
    if (isLoggedIn === "true" && userId) {
      service.playList().then((response) => {
        if (response) {
          setShowList(response.data);
          response.data.map((item, index) => {
            
            if (item.type === "REMINDERS") {
              setReminderShows(item.data);
            }
          });
        }
      });
    } else {
      navigate("/login");
    }
  }, []);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    loop: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          loop: false,
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          loop: false,
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 980,
        settings: {
          loop: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          loop: false,
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          loop: false,
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          loop: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const remindMeHandler = (id, flag, index) => {
    service.scheduleReminder(id, flag).then((response) => {
      if (response) {
        let showlist = [...ReminderShows];
        showlist[index].schedule_reminded = false;

        setReminderShows(showlist);
      }
    });
  };
  return (
    <div className="myList">
      {showList &&
        showList.filter(rows=>rows.type != 'REMINDERS').map((mainitem, index) => (
          <div key={index}>
            <h1 className="heading">{mainitem?.key}</h1>

            <div className="slickSection">
              <Slider {...settings}>
                {mainitem?.data?.map((item , i)=>(
                <div
                  className="shows"
                  key={i}
                  onClick={() => {
                    (mainitem.type === 'SHOWS') ?
                    navigate({
                      pathname: "/show",
                      search: encodeURI(`show_id=${item?.show_id}`),
                    })
                    :(

                      
                      navigate({
                        pathname: "/player",
                        search: encodeURI(`video_ID=${item?.video_id}`),
                      })
                    )
                  }}
                >
                  <Showcard
                  item={item}
                  cardHover={true}
                  metaData={true}
                  imageUrl={
                  mainitem.type === "SHOWS"
                  ? `https://gizmeon.mdc.akamaized.net/thumbnails/show_logo/` + item?.logo_thumb
                  : mainitem.type === "VIDEOS"
                  ? item?.thumbnail_350_200
                  : item?.logo_thumb
                 }
                />
                </div>
                ))}
              </Slider>
            </div>
          </div>
        ))}
      <div className="myReminderSection">
        <h1 className="reminder">My Reminders</h1>
        <div className="allReminders">

          {(ReminderShows.length > 0) ? (
            ReminderShows.map(
              (item, index) =>
                item?.schedule_reminded && (
                  
                  <div className="reminderContainer" key={index}>
                    <div className="wrapper">
                      <div className="showsContainer">
                        <div className="shows">
                          <h3> {convertTimeToLocal(item.starttime)} </h3>
                          <div className="showContainer">
                            <div className="leftContainer">
                              <div className="imageContainer">
                                <img
                                  src={
                                    `https://gizmeon.mdc.akamaized.net/thumbnails/show_logo/` +
                                    item?.thumbnail
                                  }
                                  alt="Thumbnail"
                                />
                              </div>
                            </div>
                            <div className="rightContainer">
                              <h1 className="title"> {item?.video_title} </h1>
                              <p className="description">{item?.synopsis}</p>
                              <div className="buttonContainer">
                                <button
                                // onClick={() => {
                                //   navigate({
                                //     pathname: "/show",
                                //     search: encodeURI(
                                //       `show_id=${props?.show?.show_id}`
                                //     ),
                                //   });
                                // }}
                                >
                                  On Demand
                                </button>
                                <button
                                  onClick={() =>
                                    remindMeHandler(item?.id, true, index)
                                  }
                                >
                                  Cancel Reminder
                                  <img
                                    src={require("../../assets/images/bell.png")}
                                    alt="Bell"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )
          ) : (
            <div style={{ color: "white" }}> No Reminders </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyList;
