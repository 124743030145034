import React, { useState, useEffect } from "react";
import Fingerprint2 from "fingerprintjs2";
import { getSessionId } from "./Utils/utils";

import './App.css';
import HomePage from './screens/HomePage/HomePage';
import { service } from "./Network/Network";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Livescreen from './screens/LiveScreen/LiveScreen';
import ScheduleScreen from './screens/ScheduleScreen/ScheduleScreen';
import CatchUpScreen from './screens/CatchUpScreen/CatchUpScreen';
import OnDemandScreen from './screens/OnDemandScreen/OnDemandScreen';
import SundayLiveScreen from './screens/SundayLiveScreen/SundayLiveScreen';
import PrayerScreen from './screens/PrayerScreen/PrayerScreen';
import DonateScreen from './screens/DonateScreen/DonateScreen';
import Header from './components/Header/Header';
import Register from './screens/AccountScreens/Register/Register';
import Footer from './components/Footer/Footer';
import About from './screens/About/About';
import LoginScreen from './screens/AccountScreens/LoginScreen/LoginScreen';
import AccountSettings from './screens/AccountScreens/AccountSettingsScreen/AccountSettings';
import ShowsScreen from './screens/ShowsScreens/ShowsScreen/ShowsScreen';
import SingleShowScreen from './screens/ShowsScreens/SingleShowScreen/SingleShowScreen';
import AllShows from './screens/ShowsScreens/AllShows/AllShows';
import ContactUs from './screens/ContactUs/ContactUs';
import PrivacyPolicy from './screens/PrivacyPolicyScreen/PrivacyPolicy';
import TermsAndConditions from './screens/TermsAndConditionsScreen/TermsAndConditions';
import ChangePassword from './screens/AccountScreens/ChangePasswordScreen/ChangePassword';
import MyList from "./screens/MyList/MyList";
import Player from "./screens/PlayerPage/Player";
import Search from "./screens/SearchPage/Search";
import HowToWatchScreen from "./screens/HowToWatchScreen/HowToWatchScreen";
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hideHeaderFooter, setHideHeaderFooter] = useState(false);
 
  let urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (urlParams.has('d') && urlParams.get('d') === 'm') {
      setHideHeaderFooter(true);
    } else {
      setHideHeaderFooter(false);
    }
  }, [urlParams]); 
   
  let androidToken = urlParams.get("antkn");
  if (androidToken) {
    localStorage.removeItem("userId");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    service.eraseCookie("userId");
    service.eraseCookie("userEmail");
  }

  useEffect(() => {
    if (!isAuthenticated) {
      if (service.getCookie("userId") == null) {
        if (localStorage.getItem("deviceId")) {
          service.getGeoInfo().then((response) => {
            service.getGuestUser().then((response) => {
              if (response) {
                service.authenticate().then((response) => {
                  if (response.token) {
                    setIsAuthenticated(true);
                  }
                });
              }
            });
          });
        } else {
          Fingerprint2.get(function (components) {
            var values = components.map(function (component) {
              return component.value;
            });
            var murmur = Fingerprint2.x64hash128(values.join(""), 31);
            localStorage.setItem("deviceId", murmur);
            service.getGeoInfo().then((response) => {
              service.getGuestUser().then((response) => {
                getSessionId();
                if (response) {
                  service.authenticate().then((response) => {
                    if (response.token) {
                      if (!localStorage.getItem("deviceAnalytics")) {
                        service.analytics().then((response) => {
                          localStorage.setItem("deviceAnalytics", true);
                        });
                      }

                      setIsAuthenticated(true);
                    }
                  });
                }
              });
            });
          });
        }
      } else {
        if (localStorage.getItem("deviceId")) {
          service.getGeoInfo().then((response) => {
            service.authenticate().then((response) => {
              if (response?.token) {
                setIsAuthenticated(true);
              }
              getSessionId();
              if (
                !localStorage.getItem("deviceAnalytics") &&
                service.getCookie("userId") != null &&
                service.getCookie("guestUserId") != service.getCookie("userId")
              ) {
                service.analytics().then((response) => {
                  localStorage.setItem("deviceAnalytics", true);
                });
              }

              if (
                !sessionStorage.getItem("applaunch") &&
                localStorage.getItem("isLoggedIn") == "true"
              ) {
                service.applaunchEvent("POP01").then((response) => {});
              }
            });
          });
        } else {
          Fingerprint2.get(function (components) {
            var values = components.map(function (component) {
              return component.value;
            });
            var murmur = Fingerprint2.x64hash128(values.join(""), 31);
            localStorage.setItem("deviceId", murmur);

            service.getGeoInfo().then((response) => {
              service.authenticate().then((response) => {
                if (response.token) {
                  setIsAuthenticated(true);
                }
                getSessionId();
                if (
                  !localStorage.getItem("deviceAnalytics") &&
                  service.getCookie("userId") != null &&
                  service.getCookie("guestUserId") !=
                    service.getCookie("userId")
                ) {
                  service.analytics().then((response) => {
                    localStorage.setItem("deviceAnalytics", true);
                  });
                }
                if (
                  !sessionStorage.getItem("applaunch") &&
                  localStorage.getItem("isLoggedIn") == "true"
                ) {
                  service.applaunchEvent("POP01").then((response) => {});
                }
              });
            });
          });
        }
      }
    }
  }, []);
  
  return (
    <>
    {isAuthenticated && 
    <Router>  
      {!hideHeaderFooter && <Header/> }
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/live" element={<Livescreen/>} />
        <Route path="/shows" element={<ShowsScreen/>} />
        <Route path="/schedule" element={<ScheduleScreen/>} />
        <Route path="/catchup" element={<CatchUpScreen/>} />
        <Route path="/on-demand" element={<OnDemandScreen/>} />
        {/* <Route path="/live-events" element={<SundayLiveScreen/>} /> */}
        <Route path="/my-list" element={<MyList/>} />
        <Route path="/prayer" element={<PrayerScreen/>} />
        <Route path="/donate" element={<DonateScreen/>} />
        <Route path="/register" element={<Register/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/login' element={<LoginScreen/>} />
        <Route path='/account/account-settings' element={<AccountSettings/>} />
        <Route path='/show' element={<SingleShowScreen/>} />
        <Route path='/allshows' element={<AllShows/>} />
        <Route path='/contact-us' element={<ContactUs/>} />
        <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
        <Route path='/terms-conditions' element={<TermsAndConditions/>}/>
        <Route path="/auth/reset-password/:id" element={<ChangePassword/>} />
        <Route path="/player" element={<Player/>} />
        <Route path="/search-results" element={<Search/>} />
        <Route path="/how-to-watch" element={<HowToWatchScreen/>} />
      </Routes>
      {!hideHeaderFooter && <Footer/>}   
    </Router>
    }
    </>
  );
}

export default App;
