import React, { useState, useEffect, useRef } from "react";
import "./liveScreen.css";
import Slider from "react-slick";
import { service } from "../../Network/Home/Service";
import ScheduleCard from "./ScheduleCard";
import { convertTimeToLocal } from "../../Utils/utils";
import GetNextDates from "get-next-dates";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";
import VideoJS from "../../components/Videojs/VideoJS";
import defaultPlaceHolder from "../../assets/images/PlaceHolderShows.jpg";
import useScrollPosition from "../../components/ScrollPosition/useScrollPosition";
import { ReactComponent as Closeicon } from "../../assets/images/close.svg";
import { logDOM } from "@testing-library/react";

let debounce = true
var videoStarted = false;
let seconds = 60
let prevTime
let videoPlaying = false
function LiveScreen() {
  
  const [downArrow, setDownArrow] = useState(true);
  const [filterCount, setfilterCount] = useState(3)
  const [activeButton, setActiveButton] = useState(-1);
  const [videoJSplayer, setvideoJSplayer] = useState(null);
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  let userId = service.getCookie("userId");
  const navigate = useNavigate();
  const [donateHamburgerButton, setDonateHamburgerButton] = useState(true);
  const [clickToDonate, setClickToDonate] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [LiveInfo, setLiveInfo] = useState([]);
  const [Schedule, setSchedule] = useState([]);
  const [nextDays, setnextDays] = useState([]);
  const [ShowList, setShowList] = useState([]);
  const [TodayFlag, setTodayFlag] = useState(true);
  const [EarlierShowFlag, setEarlierShowFlag] = useState(false);
  const [videoJsOptions, setVideoJsOptions] = useState(null);
  const [pipMode, setPipMode] = useState(false)
  const [browserPipMode, setBrowserPipMode] = useState(false)
  const position = useScrollPosition()
  const playerRef = useRef()
  const todayDate = new Date();


  const [triggerState, setTriggerState] = useState(false);
  const [targetTime, setTargetTime] = useState(LiveInfo?.up_next?.start_time);
  const [triggered, setTriggered] = useState(false);


  useEffect(() => {
    if (position === 0) {
      setPipMode(true)

    }
  }, [position])


  useEffect(() => {
    if (filterCount >= ShowList.length) {
      setDownArrow(false);
    } else {
      setDownArrow(true);
    }

  }, [filterCount, ShowList])

  const loadMore = () => {
    setfilterCount(filterCount + 3)
  };

  useEffect(() => {
    if (!(videoJsOptions === null)) {
      setvideoJSplayer(<VideoJS options={videoJsOptions} onReady={handlePlayerReady} />);
    }
  }, [videoJsOptions]);

  const handlePlayerReady = (player) => {

    playerRef.current = player;


    player.on("loadedmetadata", () => {

    })

    player.on("play", () => {
     
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;
      service.onVideoPlayFunctn(LiveInfo?.now_playing, event, player.currentTime()).then((response) => {
      })
    })

    player.on("timeupdate", function (e) {
      videoPlaying = true

      let event = "POP03";

      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        service.onVideoPlayFunctn(LiveInfo?.now_playing, event, player.currentTime()).then((response) => {
        })
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });

    player.on("pause", () => {
      let event = "POP04";
      service.onVideoPlayFunctn(LiveInfo?.now_playing, event, player.currentTime()).then((response) => {
      })
    })
    player.on("ended", () => {

      let event = "POP05";
      service.onVideoPlayFunctn(LiveInfo?.now_playing, event, player.currentTime()).then((response) => {
      })

    });

    player.on("dispose", () => {
      videoStarted = false
      player.pause()
    })
  };

  useEffect(() => {
    if (!EarlierShowFlag) {
      scheduleChange(todayDate.toString().substring(4, 10));
    }
  }, [EarlierShowFlag]);


  // useEffect(() => {
  //   videoscheduleChange(false);

  // }, []);

  useEffect(() => {

    const currentTime = new Date();
    const nextVideoStartTime = LiveInfo?.up_next?.start_time;
    const targetvideoTime = new Date(nextVideoStartTime);
    const timeUntilTarget = targetvideoTime - currentTime;
    const timeUntilNextVideoInSeconds = timeUntilTarget / 1000;

    if (timeUntilNextVideoInSeconds > 0 && !triggered) {
      const timeoutId = setTimeout(() => {
        setTriggerState(true);
        setTriggered(true);

      }, timeUntilTarget);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [targetTime]);


  useEffect(() => {

    videoscheduleChange(true);

  }, [triggerState]);

  const videoscheduleChange = (autoplay) => {
    // window.scrollTo(0, 0);
    setnextDays(GetNextDates(6));
    setTimeout(() =>{
      service.getLiveChannels().then((response) => {
        if (response.data) {
          setLiveInfo(response.data[0]);
          setTargetTime(response.data[0].up_next.start_time);
          // setvideoJSplayer(null);
          setVideoJsOptions({
            autoplay,
            controls: true,
            responsive: true,
            controlBar: {
              pictureInPictureToggle: false,
            },
            fluid: true,
            poster: require("../../assets/images/player-all.png"),
            sources: [
              {
                src: response.data[0].live_link,
                type: "application/x-mpegURL",
              },
            ],
          });
        }
      });
      setPlayVideo(true);
      service.getLiveSchedule(371).then((response) => {
        setSchedule(response.data);
        let data = [];
        response.data.map((item, index) => {
          const itemStartrtTime = new Date(item.starttime);
          let videoDate = dateFormat(item.starttime, "mmm dd, yyyy")
            .toString()
            .substring(0, 6);
          if (videoDate === todayDate.toString().substring(4, 10)) {
            if (itemStartrtTime >= todayDate) {
              data = [...data, item];
            }
          }
          setShowList(data);
        });
      });
   
    },5000);
  

  }

  const scheduleChange = (requestDate) => {
    setfilterCount(3)
    setEarlierShowFlag(false);
    let data = [];
    Schedule.map((item, index) => {
      const itemStartrtTime = new Date(item.starttime);
      let videoDate = dateFormat(item.starttime, "mmm dd, yyyy")
        .toString()
        .substring(0, 6);
      if (videoDate === requestDate) {
        if (itemStartrtTime >= todayDate) {
          data = [...data, item];
        }
      }
      setShowList(data);
    });
    if (todayDate.toString().substring(4, 10) === requestDate) {
      setTodayFlag(true);
    } else {
      setTodayFlag(false);
    }
  };

  const earlierShows = (requestDate) => {
    setfilterCount(3)
    let data = [];
    Schedule.map((item, index) => {
      const itemStartrtTime = new Date(item.starttime);
      let videoDate = dateFormat(item.starttime, "mmm dd, yyyy")
        .toString()
        .substring(0, 6);
      if (videoDate === requestDate) {
        if (itemStartrtTime < todayDate) {
          data = [...data, item];
        } else {
        }
      }
      setShowList(data);

    });
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const remindMeHandler = (id, flag, index) => {
    if (userId && isLoggedIn) {
      service.scheduleReminder(id, flag).then((response) => {
        if (response) {
          if (index >= 0) {
            let showlist = [...ShowList];
            showlist[index].schedule_reminded = flag ? false : true;

            setShowList(showlist);
          } else {
            let up_next_Change = { ...LiveInfo };
            up_next_Change.up_next.schedule_reminded = flag ? false : true;
            setLiveInfo(up_next_Change);
          }
        }
      });
    } else {
      navigate(`/login`);
    }
  };


  const replaceImage = (e) => {
    //replacement of broken Image
    e.target.src = defaultPlaceHolder;
  };

  window.addEventListener("enterpictureinpicture", () => {
    setBrowserPipMode(true)
  })

  window.addEventListener("leavepictureinpicture", () => {
    setBrowserPipMode(false)
  })

  return (
    <div id="live">
      <div className="wrapper">
        <div id="spotlight">
          <div
            className={!playVideo ? "thumbnailContainer" : "no-active"}
            onClick={() => {
              setPlayVideo(true);
              setDonateHamburgerButton(false);
            }}
          >
            <img
              className="bg"
              src={require("../../assets/images/player-all.png")}
              alt="Background"
            />
            <img
              className="play-icon"
              src={require("../../assets/images/play.svg").default}
              alt=""
            />
          </div>
          {
            position > 700 && pipMode === true && videoPlaying === true && browserPipMode === false &&
            <div
              className={"thumbnailContainer"}

            >
              <img
                className="bg"
                src={require("../../assets/images/resized-poster-image.png")}
                alt="Background"
              />
            </div>
          }
          <div className={"videoContainer"}>
            <div className={pipMode === true && position > 700 && browserPipMode === false && videoPlaying === true ? "Vplayer pipMode" : "Vplayer"}>
              <div className="closeIcon" onClick={() => setPipMode(false)}>
                <Closeicon />
              </div>
              {videoJSplayer}
            </div>
          </div>
          <div className="nowPlaying">
            <div className="top">
              <h3>Now Playing</h3>
              <span>
                {" "}
                {LiveInfo?.now_playing?.video_title?.slice(0, 25)}
                {LiveInfo?.now_playing?.video_title?.length > 25 && "..."}{" "}
              </span>
            </div>
            <h3>
              {LiveInfo?.now_playing?.start_time &&
                convertTimeToLocal(LiveInfo?.now_playing?.start_time)}
            </h3>
            <p>{LiveInfo?.now_playing?.video_description}</p>
          </div>
          <div className="donate">
            <div
              className={
                donateHamburgerButton ? "active donateButton" : "no-active"
              }
              onClick={() => {
                setDonateHamburgerButton(false);
                setClickToDonate(true);
              }}
            >
              <img
                src={require("../../assets/images/live-tab.png")}
                alt="Donate-hamburger"
              />
            </div>
            <div
              className={clickToDonate ? "clickToDonate" : "no-active"}
              onClick={() => {
                setClickToDonate(false);
                setDonateHamburgerButton(true);
              }}
            >
              <img
                src={require("../../assets/images/donate.png")}
                alt="Donate"
              />
            </div>
          </div>
        </div>{" "}
        {/* ( #spotlight ) */}
      </div>{" "}
      {/* ( wrapper ) */}
      <div id="upComing">
        {LiveInfo?.up_next && <h1 className="upTitle">Up Next</h1>}
        <div className="allContainers">

          <div className="wrapper">

            {LiveInfo?.up_next && (
              <div className="upNextShowContainer">
                <h3>
                  {LiveInfo?.up_next?.start_time &&
                    convertTimeToLocal(LiveInfo?.up_next?.start_time)}
                </h3>
                <div className="showContainer">
                  <div className="leftContainer">
                    <div className="imageContainer">
                      <img
                        src={
                          "https://gizmeon.mdc.akamaized.net/thumbnails/thumbnails/" +
                          LiveInfo?.up_next?.thumbnail_350_200
                        }
                        onError={(e) => replaceImage(e)}
                        alt="Thumbnail"
                      />
                    </div>
                  </div>
                  <div className="rightContainer">
                    <h1 className="title">{LiveInfo?.up_next?.video_title}</h1>
                    <p className="description">
                      {LiveInfo?.up_next?.video_description}
                    </p>
                    <div className="buttonContainer">
                      <button
                        onClick={() => {
                          navigate({
                            pathname: "/show",
                            search: encodeURI(
                              `show_id=${LiveInfo?.up_next?.show_id}`
                            ),
                          });
                        }}
                      >
                        On Demand
                      </button>
                      {!LiveInfo?.up_next?.schedule_reminded ? (
                        <button
                          onClick={() =>
                            remindMeHandler(LiveInfo?.up_next?.id, false)
                          }
                        >
                          Remind me
                          <img
                            src={require("../../assets/images/bell.png")}
                            alt="Bell"
                          />
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            remindMeHandler(LiveInfo?.up_next?.id, true)
                          }
                        >
                          Cancel Reminder
                          <img
                            src={require("../../assets/images/bell.png")}
                            alt="Bell"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {/* ( showContainer ) */}
              </div>
            )}
            {/* ( upNextShowContainer ) */}
            <div className="comingUpShowContainer">
              <h1 className="comingUp">Coming Up</h1>
              <div className="weeklyContainer">
                <Slider {...settings}>
                  <div className="weeks">
                    <button
                      className={
                        activeButton === -1
                          ? "activeButtonBgChange weekDays"
                          : "weekDays"
                      }
                      onClick={() => {
                        setActiveButton(-1);

                        scheduleChange(todayDate.toString().substring(4, 10));
                        setEarlierShowFlag(false);
                      }}
                    >
                      Today
                    </button>
                  </div>
                  {nextDays &&
                    nextDays.map((item, index) => (
                      <div className="weeks" key={index}>
                        <button
                          className={
                            activeButton === index
                              ? "activeButtonBgChange weekDays"
                              : "weekDays"
                          }
                          onClick={() => {
                            setActiveButton(index);
                            scheduleChange(item.toString().substring(4, 10));
                          }}
                        >
                          {item.toString().substring(0, 3) +
                            " " +
                            dateFormat(item, "dS")}
                        </button>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>{" "}
            {/* ( comingUpShowContainer ) */}
            <div
              className="earlierShowsContainer"
              style={{ display: !TodayFlag && "none" }}
            >
              <button
                onClick={() => {
                  setEarlierShowFlag(!EarlierShowFlag);

                  earlierShows(todayDate.toString().substring(4, 10));
                }}
              >
                Earlier Shows
                <img
                  style={{ transform: EarlierShowFlag && "scaleY(-1)" }}
                  src={require("../../assets/images/arrow-down.png")}
                  alt="Down-arrow"
                />
              </button>
            </div>
          </div>
        </div>{" "}
        {/* ( allContaieners ) */}
      </div>
      {/* ( #upNext ) */}
      <div id="allShows">
        <div className="wrapper">
          <div className="showsContainer">
            {ShowList.length > 0 ?
              (TodayFlag && !EarlierShowFlag
                ? ShowList?.filter((item, index) => index < filterCount)?.map(
                  (item, index) =>
                    !(index === 0) &&
                    (
                      <ScheduleCard
                        show={item}
                        key={index}
                        index={index}
                        remindFunction={remindMeHandler}
                      />
                    )
                )
                : EarlierShowFlag
                  ? ShowList?.filter((item, index) => index < filterCount)?.map(
                    (item, index) =>
                      !(index === ShowList.length - 1) && (
                        <ScheduleCard
                          show={item}
                          key={index}
                          index={index}
                          remindFunction={remindMeHandler}
                          earlyFlag={EarlierShowFlag}
                        />
                      )
                  )
                  : ShowList?.filter((item, index) => index < filterCount)?.map((item, index) => (
                    <ScheduleCard
                      show={item}
                      key={index}
                      index={index}
                      remindFunction={remindMeHandler}
                    />
                  ))) :
              `No shows to list`}
            {/* // ( allshows ) */}
          </div>
          <div className="bottom" style={{ display: !downArrow && 'none' }}>
            {(downArrow) &&
              <div
                className="downArrowContainer"
                onClick={loadMore}
              >
                <img
                  src={require("../../assets/images/down-arrow-flat.png")}
                  alt="Down-arrow"
                />
              </div>
            }
          </div>
        </div>

      </div>

    </div>
  );
}

export default LiveScreen;